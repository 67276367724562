<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="!templateId">
          Add Template
        </h1>
        <h1 v-if="templateId">
          Edit Template
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="primary"
          @click="template()"
        >
          Template
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!templateId"
          title="Template Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="templateId"
          title="Template Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
      <div v-if="showAlertSlugexit">
        <el-alert
          title="The slug has already been taken."
          type="error"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>

  <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition" 
  >
  <el-row :gutter="20">
      <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="18"
          :xl="18"
      >
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Template Name"
          prop="name"
        >
          <el-input
            v-model="data.name"
            type="text"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      ><input
            id="path"
            v-model="data.path"
            name="path"
            type="hidden"
          >
        
      </el-col>
    </el-row>
    
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item
          label="Template Description"
          prop="description"
        >
          <el-input
            v-model="data.description"
            type="textarea"
            :rows="15"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>

      <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
      >
        <el-form-item
            label="Template Preview Image"
            prop="preview_image"
        >
          <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileList"
              :limit="1"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleImageUploadSuccess"
              :on-error="handleImageUploadError"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
              <template #tip>
                <div class="el-upload__tip">
                  JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
                </div>
              </template>
            </el-upload>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="12"
        :xl="12"
      >
        <el-form-item>
          <input
            id="templateId"
            v-model="templateId"
            name="templateId"
            type="hidden"
          >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            Submit
          </el-button>
          <el-button  v-if="!templateId" @click="resetForm('ruleForm')">
            Reset
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
	

    <el-row :gutter="20">
      <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5"
      >
        <el-form-item
            label="Edit File"
            prop="filepath"
        >
          <el-select v-model="editFile.path" placeholder="Select File" @change="getFileContent">
            <el-option
                v-for="item in filesList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
	  <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5"
      >
        <el-form-item
            label="Edit File CSS"
            prop="filepathcss"
        >
          <el-select v-model="editFile.pathcss" placeholder="Select File" @change="getFileContentCss">
            <el-option
                v-for="item in filesListCss"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
	  <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="5"
          :xl="5"
      >
        <el-form-item
            label="Edit File JS"
            prop="filepathjs"
        >
          <el-select v-model="editFile.pathjs" placeholder="Select File" @change="getFileContentJs">
            <el-option
                v-for="item in filesListJs"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
	  
    </el-row>

	 </el-col>
<el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="6"
          :xl="6"
      >
<el-row :gutter="20"  v-if="templateId">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div class="heading">       
        <h1>
          Template Icons Images
        </h1>
      </div>
    </el-col>
	 <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="24"
          :xl="24"
      >
        <el-form-item
            label="Template Icons Images"
        >
          <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrlIcons"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="filesListIconsUploads"
              :limit="10"
              :on-preview="handlePreviewIcons"
              :on-remove="handleRemoveIcons"
              :on-success="handleImageUploadSuccessIcons"
              :on-error="handleImageUploadErrorIcons"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
              <template #tip>
                <div class="el-upload__tip">
                  JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
                </div>
              </template>
            </el-upload>
        </el-form-item>
      </el-col>
    </el-row>
	<el-row :gutter="20"  v-if="templateId">
      <el-col
        :xs="24"
        :sm="24"
        :md="24"
        :lg="8"
        :xl="8"
		v-for="item in filesListIcons"
         :key="item.value" >
           <img :src= "item.value" style="height: 30px !important;width: 35px !important;"><br><a @click="copyURL(item.label)">&nbsp;Copy</a>
      </el-col>
    </el-row>
      </el-col>
    </el-row>
	
    <el-row :gutter="20" v-if="this.editFile.path">
      <el-col
          :xs="24"
          :sm="24"
          :md="24"
          :lg="8"
          :xl="8"
      >
        <el-form-item
            label="File Content"
            prop="filecontent"
        >
          <el-button type="danger" plain size="small" @click="saveFileContent">Save Original File</el-button>
          <el-button type="primary" plain size="small" @click="saveDBFileContent">Save DB File</el-button>
          <CodeEditor
              :markup="editFile.content"
              @codeChanged="updateFileContent"
              :key="editFile.path"
          ></CodeEditor>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>

import TemplateService from "@/api/template.service";
import authHeader from '@/api/auth-header';
import CodeEditor from "@/components/CodeEditor";
import useClipboard from 'vue-clipboard3'
export default {
  components: {CodeEditor},
  data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Template Name.'));
        } else {
          callback();
        }
      };
      var checkPath = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the template Folder Name.'));
        } else {
          callback();
        }
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        labelPosition: 'top',
        templateId: this.$route.params.templateId,
         imageUploadUrl: process.env.VUE_APP_BASE_API,
         imageUploadUrlIcons: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
        filesList: [],
        filesListCss: [],
        filesListJs: [],
        fileList:[],
        filesListIcons:[],
        filesListIconsUploads:[],
        editFile: {
          path: null,
          filedir: null,
          content: null
        },
        data: {
          name: null,
          path:null,
          description:null,
          preview_image:null
        },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ],
          path: [
            { validator: checkPath, trigger: 'blur' }
          ]
        },
      };
    },
	mounted() {
		if(this.templateId){
			this.getTemplate();
		}
		if(!this.templateId){
			this.getThemeName();
		}
		this.setImageUploadUrl();
		
	},
  watch: {
    data:  function (value) {
      if(value.path) {
        this.getFilesList();
        this.getFilesListIcons();
        this.getFilesListCss();
        this.getFilesListJs();
		this.setImageUploadUrlIcons();
      }
    }
  },
  computed: {
       loadEditor: function () {
        return !this.templateId || (this.templateId && this.data.description);
        },
  },
    methods: {
      updateFileContent(value) {
        this.editFile.content = value
      },
	async copyURL(mytext) {
		try {
		  const { toClipboard } = useClipboard()
		  await toClipboard(mytext);
		  //console.log('Copied!!');
		} catch($e) {
		  console.log('Cannot copy!!');
		}
	 },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.templateId) {
             return TemplateService.add(this.data).then(response => {
                this.showAlertAdded=true ;
                return response;
              });
          } else if (valid && this.templateId) {
            return TemplateService.update(this.templateId, this.data).then(response => {
              this.showAlertAdded=true;
              return response;

            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      template(){
        this.$router.push("/template");
      },
      saveDBFileContent() {
        //TODO: Save DB File Content
        console.log("TODO:// Save DB File")
      },
      saveFileContent() {
        return TemplateService.saveFileContent(this.editFile).then(response => {
          this.editFile.content = response.data.data
        });
      },
      getFileContent(value) {
        const filepath = this.editFile.path;
        this.editFile.path = null;
        this.editFile.content = null;
        this.editFile.filedir = 'views';
        this.editFile.path = filepath;
        return TemplateService.getFileContent(this.editFile.path).then(response => {
          this.editFile.content = response.data.data
        });
      },
      getFileContentCss(value) {
        const filepath = this.editFile.pathcss;
        this.editFile.path = null;
        this.editFile.content = null;
		this.editFile.filedir = 'css';
        this.editFile.path = filepath;
        return TemplateService.getFileContentCss(this.editFile.path).then(response => {
          this.editFile.content = response.data.data
        });
      },
      getFileContentJs(value) {
        const filepath = this.editFile.pathjs;
        this.editFile.path = null;
        this.editFile.content = null;
        this.editFile.path = filepath;
		this.editFile.filedir = 'js';
        return TemplateService.getFileContentJs(this.editFile.path).then(response => {
          this.editFile.content = response.data.data
        });
      },
      getFilesList() {
        return TemplateService.getFilesList(this.data.path).then(response => {
          this.filesList = response.data.data.map((file)=> {
            return {
              value: file.value,
              label: file.label,
            }
          })
        });
      },
	getFilesListCss() {
        return TemplateService.getFilesListCss(this.data.path).then(response => {
          this.filesListCss = response.data.data.map((file)=> {
            return {
              value: file.value,
              label: file.label,
            }
          })
        });
      },
	getFilesListJs() {
        return TemplateService.getFilesListJs(this.data.path).then(response => {
          this.filesListJs = response.data.data.map((file)=> {
            return {
              value: file.value,
              label: file.label,
            }
          })
        });
      },
	getFilesListIcons() {
        return TemplateService.getFilesListIcons(this.data.path).then(response => {
          this.filesListIcons = response.data.data.map((file)=> {
            return {
              value: file.value,
              url: file.value,
              label: file.label,
            }
          })
        });
      },
      getTemplate(){
        return TemplateService.get(this.templateId).then(response => {
            this.data = response.data.data;
            this.fileList.push({name:'', url: response.data.data.preview});

          });
      }, 
	getThemeName(){
    return TemplateService.getThemeName().then(response => {
        this.data.path =response.data.data.theme_path;
      });
	},
      setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {         
          this.data.preview_image = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },
      setImageUploadUrlIcons() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrlIcons = process.env.VUE_APP_BASE_API + 'image-upload-icons/'+this.data.path;
      },
      handleImageUploadSuccessIcons(response, file, filesListIcons) {
        if(response.code == 200) {
			this.filesListIcons.push({name:'',  value: response.image_url, label: response.image_url,url: response.image_url});
        }
      },
      handleImageUploadErrorIcons(err, file, filesListIcons) {
        console.log(err)
      },
      handlePreviewIcons(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveIcons(file, filesListIcons) {
        console.log(file, filesListIcons)
        //TODO: Delete the file
      },
    }
  }
</script>
<style lang="scss">
.el-checkbox{
  width: 20%;
  text-transform: capitalize;
}
.el-select-dropdown{
      text-transform: capitalize;
}
.el-select .el-input__inner{
  text-transform: capitalize;
}
</style>
<style lang="scss" scoped>
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>
